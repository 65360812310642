import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import './OpeningHoursTable.scss'
import {MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableRow,} from '@mui/material';
import {getNodeContext} from "../../utils/utils";

const timeOptions = Array.from({length: 24}, (_, i) => `${i.toString().padStart(2, '0')}:00`);
const padding= '0px'
const OpeningHoursTable = ({handleOpeningHoursChange, initialHours}) => {
    const [daysOfWeek] = useState(getNodeContext()?.country === 'Israel' ? ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'] : ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'])
    const [t] = useTranslation();
    const [hours, setHours] = useState(initialHours.length ? initialHours : daysOfWeek.map((day) => ({
        day, open: '', close: ''
    })));

    const handleChange = (day, type, value) => {
        const newHours = hours.map((entry) => {
            if (entry.day === day) {
                if (type === 'open') {
                    return {...entry, open: value, close: value<entry.close?entry.close:timeOptions[timeOptions.indexOf(value)+1]};
                }
                return {...entry, [type]: value};
            }
            return entry;
        })
        setHours(newHours)
        handleOpeningHoursChange(newHours)
    };

    return (<div id={'opening-hours-table'}>
            <TableContainer component={Paper}
                            style={{
                                width: "100%",
                                height: '400px',
                                maxWidth: '450px',
                                backgroundColor: '#001333',
                                color: '#9DADBC',
                                justifyContent:'flex-start'
                            }}>
                <div style={{
                    color: 'var(--title-color)',
                    fontSize: '1rem',
                    textAlign: 'left',
                    fontWeight:500,
                    padding: '0 0 15px 15px'
                }}>
                    {t('openingHours')}
                </div>
                <Table
                    size="small"
                    style={{
                        backgroundColor: '#001333',
                        color: '#9DADBC',
                        borderCollapse: 'collapse',
                        tableLayout: 'fixed',
                        height: '80%',
                    }}
                >

                    <TableBody>
                        {hours.map(({day, open, close}) => (
                            <TableRow key={day}>
                                <TableCell sx={{padding:'5px 15px '}} style={{
                                    color: '#9DADBC', fontSize: '1rem', borderBottom: '1px solid var(--primary-bg)',
                                }}>{t(day)}</TableCell>
                                <TableCell sx={{padding}} style={{borderBottom: '1px solid var(--primary-bg)'}}>
                                    <Select
                                        value={open}
                                        onChange={(e) => handleChange(day, 'open', e.target.value)}
                                        displayEmpty
                                        fullWidth
                                        style={{
                                            backgroundColor: '#001333',
                                            color: open ? '#9DADBC' : '#AABCCC',
                                            fontSize: '1rem',
                                            border: '1px solid var(--primary-bg)',
                                            height: '33px',
                                        }}
                                        sx={{
                                            '.MuiSvgIcon-root': {
                                                fill: '#001333 !important',
                                            },
                                        }}
                                        MenuProps={{
                                            sx: {
                                                '&& .MuiPaper-root': {
                                                    color: '#EAFBFF',
                                                    backgroundColor: '#00173D',
                                                    border: '1px solid #3D5484',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="" style={{
                                            backgroundColor: '#001333', color: '#9DADBC', border: '1px solid var(--primary-bg)'
                                        }}>
                                            <em>{t('none')}</em>
                                        </MenuItem>
                                        {timeOptions.map((time) => (<MenuItem
                                            key={time}
                                            value={time}
                                            style={{
                                                backgroundColor: '#001333',
                                                color: '#9DADBC',
                                                border: '1px solid var(--primary-bg)'
                                            }}
                                        >
                                            {time}
                                        </MenuItem>))}
                                    </Select>
                                </TableCell>
                                <TableCell sx={{padding}} style={{borderBottom: '1px solid var(--primary-bg)'}}>
                                    <Select
                                        value={close}
                                        onChange={(e) => handleChange(day, 'close', e.target.value)}
                                        displayEmpty
                                        fullWidth
                                        style={{
                                            backgroundColor: '#001333',
                                            fontSize: '1rem',
                                            border: '1px solid var(--primary-bg)',
                                            height: '33px',
                                        }}
                                        sx={{
                                            '.MuiSvgIcon-root': {
                                                fill: '#001333 !important',
                                            }, color: '#9DADBC', "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "rgba(157,173,188,0.50)",
                                            },
                                        }}
                                        disabled={!open}
                                        MenuProps={{
                                            sx: {
                                                '&& .MuiPaper-root': {
                                                    color: '#EAFBFF',
                                                    backgroundColor: '#00173D',
                                                    border: '1px solid #3D5484',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="" style={{
                                            backgroundColor: '#001333', color: '#9DADBC', border: '1px solid var(--primary-bg)'
                                        }}>
                                            <em>{t('none')}</em>
                                        </MenuItem>
                                        {timeOptions
                                            .filter((time) => time > open)
                                            .map((time) => (<MenuItem
                                                key={time}
                                                value={time}
                                                style={{
                                                    backgroundColor: '#001333',
                                                    color: '#9DADBC',
                                                    border: '1px solid var(--primary-bg)'
                                                }}
                                            >
                                                {time}
                                            </MenuItem>))}
                                    </Select>
                                </TableCell>
                            </TableRow>))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>)
};

OpeningHoursTable.propTypes = {
    initialHours: PropTypes.arrayOf(PropTypes.shape({
        day: PropTypes.string.isRequired, open: PropTypes.string, close: PropTypes.string,
    })), handleOpeningHoursChange: PropTypes.func.isRequired, weekStartsOnSunday: PropTypes.bool,
};

export default OpeningHoursTable;

import './ReportsPoolUtilizationPage.scss'
import {ButtonGroup, MenuItem, Select} from "@mui/material";
import Button from "@mui/material/Button";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {useTranslation} from "react-i18next";
import {getNodeContext, weekDays} from "../../../utils/utils";
import {useEffect, useState} from "react";

import moment from "moment";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis,} from "recharts";
import ReportsDiagramPane from "../ReportsDiagramPane/ReportsDiagramPane";
import {fetchPoolAlertsAndImages, fetchPoolHeatmapData, getPoolReport} from "../../../api/api";
import {alertPropsMap} from "../../../utils/Themes";
import {transformLinearVector} from "../../../utils/LinearCalcHelper";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import ImageCarousel from "../../ImageCarousel/ImageCarousel";
import {DateTime} from "luxon";

const defaultHeatmapData = [{max: -1, data: []}, {max: -1, data: []}, {max: -1, data: []}, {
    max: -1, data: []
}, {max: -1, data: []}, {max: -1, data: []}, {max: -1, data: []}]
const ReportsPoolUtilizationPage = props => {
    const transformationDimensions = {height: 710, width: 1380}
    const height = 250
    const layoutDimensions = {
        height: height, width: height * transformationDimensions.width / transformationDimensions.height
    }
    const adjustmentHeight = 0
    const adjustmentWidth = -5
    const scale = {
        x: (layoutDimensions.width) / transformationDimensions.width,
        y: (layoutDimensions.height) / transformationDimensions.height
    }
    const [context] = useState(getNodeContext() || {})
    const isIsraelWeek = context.timezone === 'Asia/Jerusalem'
    const [t] = useTranslation()
    const timezone = getNodeContext().timezone
    let startWeek
    if (isIsraelWeek) {
        const today = DateTime.local().setZone(timezone).startOf('day')
        if (today.weekday === 7) {
            startWeek = today.startOf('week').minus({day: 1})
        } else {
            startWeek = today.minus({week: 1}).startOf('week').minus({day: 1})
        }
    } else {
        startWeek = DateTime.local().setZone(timezone).startOf('day').minus({week: 1}).startOf('week').minus(isIsraelWeek ? {day: 1} : {day: 0})
    }
    const legendStringsMap = {swimmerCountAvg: "last4WeeksAverage", swimmerCount: 'swimmers'}
    const [fromDate] = useState(startWeek.toISO())

    const [pools] = useState(context.poolIds.map((id, index) => {
        return {
            id,
            area: context.poolAreas[index],
            title: context.dashboardNames[index],
            transformation: context.poolTransformations[index]
        }
    }))
    const [alertWithImagesMap, setAlertWithImagesMap] = useState(null)
    const [alertWithImagesArraySorted, setAlertWithImagesArraySorted] = useState([])
    const [hoveredAlert, setHoveredAlert] = useState(null)
    const [heatMapData, setHeatMapData] = useState(defaultHeatmapData)
    const [data, setData] = useState([])
    const [selectedPoolIndex, setSelectedPoolIndex] = useState(0)
    const [selectedAlertIndex, setSelectedAlertIndex] = useState(0)
    const [alerts, setAlerts] = useState([])
    const [weekDay, setWeekDay] = useState(weekDays[0].value)
    const [reportSwimmerHistoryData, setReportSwimmerHistoryData] = useState(null)
    useEffect(() => {
        fetchAllReportData()
        fetchHeatmapsForPool()
        fetchAlertsAndImages()

    }, [])


    function clearHoveredAlerts() {
        setAlertsForDiagram([])
        setHoveredAlert(null)
        setSelectedAlertIndex(0)
    }

    const CustomDot = ({cx, cy, payload, value}) => {
        if (payload && payload.alerts) {
            const alerts = payload.alerts.alerts || []
            return <svg className={'dot'} onMouseLeave={clearHoveredAlerts}>
                {alerts.map((alert, index) => (<circle onMouseEnter={alertHoverProcess}
                                                       key={index + "key"}
                                                       cx={cx}
                                                       cy={cy - 15 * index}
                                                       values={alert.time}
                                                       r={4}
                                                       stroke={alertPropsMap[alert.description.toLowerCase()].color}
                                                       strokeWidth={4}
                                                       fill={""}/>))}
            </svg>
        }
        return <svg/>
    }

    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            const data = reportSwimmerHistoryData && reportSwimmerHistoryData.find(e => e.time === label) || {}
            const _alerts = data.alerts
            if (_alerts && _alerts.alerts && _alerts.alerts.length) {
                if (!alerts || !alerts.length) {
                    setAlertsForDiagram(_alerts.alerts)
                } else if (alerts && alerts.length && _alerts.alerts.length && alerts[0].location[0] !== _alerts.alerts[0].location[0]) {
                    setAlertsForDiagram(_alerts.alerts)
                }
            } else {
                if (alerts && alerts.length) {
                    setAlertsForDiagram([])
                    setHoveredAlert(null)
                }
            }
            return (<div key={Math.random() + '-key'} className="custom-tooltip">
                <p className={"title"}>{data.time}</p>
                <div className={"tooltip-container"}>
                    <div className='label-container'>
                        <div className={`dot`} style={{backgroundColor: '#57A6DB'}}/>
                        <div className={'label'}>{t('swimmers')}</div>
                        <div className={'label'}>{data.swimmerCount}</div>
                    </div>
                    <div className='label-container'>
                        <div className={`dot`} style={{backgroundColor: '#6A6EAD'}}/>
                        <div className={'label'}>{t('last4WeeksAverage')}</div>
                        <div className={'label'}>{data.swimmerCountAvg}</div>
                    </div>
                </div>
            </div>)
        }
        return null;
    }

    function fetchAlertsAndImages(poolIndex = selectedPoolIndex, wd = weekDay) {
        const poolId = pools[poolIndex].id
        Promise.all([fetchPoolAlertsAndImages(moment(fromDate).add(wd, 'days').toISOString(), moment(fromDate).add(wd + 1, 'day').toISOString(), poolId),])
            .then(([_alertsMap]) => {
                const _arraySorted = Object.values(_alertsMap).sort((a, b) => a.time.localeCompare(b.time))
                setAlertWithImagesArraySorted(_arraySorted)
                setAlertWithImagesMap(_alertsMap)
            }).catch(ex => {
            console.log("fetch alerts ex", ex)
        })
    }

    function fetchHeatmapsForPool(poolIndex = selectedPoolIndex) {
        const poolId = pools[poolIndex]?.id
        Promise.all([fetchPoolHeatmapData(moment(fromDate).add(0, 'day').toISOString(), moment(fromDate).add(1, 'day').toISOString(), poolId), fetchPoolHeatmapData(moment(fromDate).add(1, 'day').toISOString(), moment(fromDate).add(2, 'day').toISOString(), poolId), fetchPoolHeatmapData(moment(fromDate).add(2, 'day').toISOString(), moment(fromDate).add(3, 'day').toISOString(), poolId), fetchPoolHeatmapData(moment(fromDate).add(3, 'day').toISOString(), moment(fromDate).add(4, 'day').toISOString(), poolId), fetchPoolHeatmapData(moment(fromDate).add(4, 'day').toISOString(), moment(fromDate).add(5, 'day').toISOString(), poolId), fetchPoolHeatmapData(moment(fromDate).add(5, 'day').toISOString(), moment(fromDate).add(6, 'day').toISOString(), poolId), fetchPoolHeatmapData(moment(fromDate).add(6, 'day').toISOString(), moment(fromDate).add(7, 'day').toISOString(), poolId)])
            .then(([day1, day2, day3, day4, day5, day6, day7]) => {
                const data = [day1, day2, day3, day4, day5, day6, day7]
                const parsedData = data.map(heatMapData => {
                    const swimmersLocationsTransformed = heatMapData.data && heatMapData.data.map(l => {
                        const point = transformLinearVector(pools[poolIndex].transformation, l)
                        const newPoint = {
                            x: adjustmentWidth + (scale.x * point.x),
                            y: adjustmentHeight + (scale.y * point.y),
                            value: l.value
                        }
                        return {x: Math.round(newPoint.x), y: Math.round(newPoint.y), value: l.value}
                    })
                    return {
                        data: swimmersLocationsTransformed || [],
                        topPercentage: heatMapData.topPercentage,
                        max: heatMapData.max || 0
                    }
                })
                setHeatMapData(parsedData)
            }).catch((err) => {
            console.log("CANT FETCH HEATMAP ", err)
        })
    }

    function fetchAllReportData(poolIndex = selectedPoolIndex) {
        const _poolId = pools[poolIndex]?.id
        if (_poolId) {
            Promise.all([getPoolReport(_poolId, moment(fromDate).add(0, 'day').toISOString(), moment(fromDate).add(1, 'day').toISOString()), getPoolReport(_poolId, moment(fromDate).add(1, 'day').toISOString(), moment(fromDate).add(2, 'day').toISOString()), getPoolReport(_poolId, moment(fromDate).add(2, 'day').toISOString(), moment(fromDate).add(3, 'day').toISOString()), getPoolReport(_poolId, moment(fromDate).add(3, 'day').toISOString(), moment(fromDate).add(4, 'day').toISOString()), getPoolReport(_poolId, moment(fromDate).add(4, 'day').toISOString(), moment(fromDate).add(5, 'day').toISOString()), getPoolReport(_poolId, moment(fromDate).add(5, 'day').toISOString(), moment(fromDate).add(6, 'day').toISOString()), getPoolReport(_poolId, moment(fromDate).add(6, 'day').toISOString(), moment(fromDate).add(7, 'day').toISOString())])
                .then(([day1, day2, day3, day4, day5, day6, day7]) => {
                    const _data = [day1.data, day2.data, day3.data, day4.data, day5.data, day6.data, day7.data]
                    setData(_data)
                    setReportSwimmerHistoryData(_data[weekDay])
                })
        }else{
            console.log("Error => No pools found ", )
        }
    }

    const legendFormatter = (str) => {
        if (str === 'swimmerCount') {
            return <span className={'legend-title'}>
                {moment(fromDate).add(weekDay, "day").format('MMM DD')}
            </span>
        }
        const mappedStr = legendStringsMap[str] || ''
        return mappedStr && <span className={'legend-title'}>{t(mappedStr)}</span> || ''
    }

    function handlePoolsSelection(inc) {
        setHeatMapData(defaultHeatmapData)
        const poolCount = pools.length
        let sPool = selectedPoolIndex + inc
        if (sPool < 0) {
            sPool = pools.length - 1
        }
        sPool = sPool % poolCount
        setAlertsForDiagram(null)
        setData(null)
        setHeatMapData(null)
        setAlertWithImagesArraySorted(null)
        setAlertWithImagesMap(null)
        fetchAllReportData(sPool)
        fetchHeatmapsForPool(sPool)
        fetchAlertsAndImages(sPool)
        setSelectedPoolIndex(sPool)
    }

    function handleDaySelection(ev) {
        const wd = ev.target.value
        setWeekDay(wd)
        setSelectedAlertIndex(0)
        setAlertWithImagesMap({})
        setAlertWithImagesArraySorted([])
        fetchAlertsAndImages(selectedPoolIndex, wd)
        setReportSwimmerHistoryData(data[wd])
        setSelectedAlertIndex(0)
    }

    function handleDayChange(inc) {
        setSelectedAlertIndex(0)
        let wd = weekDay + inc
        if (wd < 0) {
            wd = 6
        }
        wd = wd % 7
        setWeekDay(wd)
        fetchAlertsAndImages(selectedPoolIndex, wd)
        setReportSwimmerHistoryData(data[wd])

    }

    function setAlertsForDiagram(_alerts) {
        setAlerts(_alerts)
    }

    function alertHoverProcess(ev) {
        const value = ev.target.attributes.values && ev.target.attributes.values.nodeValue
        const alert = alertWithImagesMap && alertWithImagesMap[value] || null
        const _alertIndex = alertWithImagesArraySorted?.findIndex(e => e.time === value)
        setSelectedAlertIndex(_alertIndex)
        if (!alert) {
            console.log(" didnt find image for this timestamp", value)
            setAlertsForDiagram([])
        } else {
            setHoveredAlert(alert)
            if (alerts.length !== 1) {
                setAlertsForDiagram([alert])
            } else if (alerts.length === 1 && alert.time !== alerts[0].time) {
                setAlertsForDiagram([alert])
            }

        }
    }

    function processSelectedEventFromCarousel(index) {
        const _alert = alertWithImagesArraySorted ? alertWithImagesArraySorted[index] : null
        setAlerts([_alert])
        setSelectedAlertIndex(index)
    }

    function renderButtonGroup() {
        if (context.poolIds.length === 1) {
            return <></>
        } else {
            return context.poolIds.length > 1 && data && heatMapData && alertWithImagesArraySorted && alertWithImagesMap &&
                <ButtonGroup className={'button-group'}>
                    <Button onClick={() => handlePoolsSelection(-1)}>
                        <KeyboardArrowLeftIcon/>
                    </Button>
                    <Button onClick={() => handlePoolsSelection(1)}>
                        <KeyboardArrowRightIcon/>
                    </Button>
                </ButtonGroup> || <div className={'spinner-container'}>
                    <LoadingSpinner size={30} top={65} right={20}/>
                </div>
        }
    }

    return <div id={'reports-pool-utilization-page'}>
        <div className={'top-pane'}>
            <div className={'top-menu'}>
                {renderButtonGroup()}
                <div className={'pool-name-title'}>{pools[selectedPoolIndex]?.title}</div>
            </div>
            <div className={'graph-area-rp'}>
                <div className={'day-selection-area'}>
                    <div className={'main-title'}>{t('swimmerCount')}</div>
                    <div className={'day-select'}>
                        <div className={'day-title'}>{t('day')}</div>
                        <div className={'my-buttons-group'}>
                            <Select
                                className={'select-cus'}
                                IconComponent={() => null}
                                sx={{
                                    width: 130,
                                    height: 40,
                                    border: 'none',
                                    backgroundColor: "#00173D",
                                    fontSize: '14px',
                                    color: "#EAFBFF"
                                }}
                                value={weekDay}
                                MenuProps={{
                                    sx: {
                                        "&& .Mui-selected": {
                                            color: '#EAFBFF', backgroundColor: "#57A6DB"
                                        }, "&& .Mui-selected:hover": {
                                            backgroundColor: "#57A6DB"
                                        }, "&& .MuiPaper-root": {
                                            color: '#EAFBFF', backgroundColor: "#00173D"
                                        }, "&& .MuiMenuItem-root:hover": {
                                            color: '#EAFBFF',
                                            backgroundColor: "#57A6DB",
                                            transition: ' all 130ms cubic-bezier(0.955, 0.48, 0.725, 0.14)'
                                        }
                                    }
                                }}
                                onChange={handleDaySelection}>
                                {weekDays.map((weekDay) => (<MenuItem className={'week-day-item'}
                                                                      sx={{
                                                                          fontSize: '14px', color: "#EAFBFF"
                                                                      }}
                                                                      key={Math.random() + weekDay.title}
                                                                      value={weekDay.value}>
                                    <div style={{
                                        width: '100%', display: "inline-flex", justifyContent: "space-between"
                                    }}>
                                        <div>{t(isIsraelWeek ? weekDay.titleIl : weekDay.title)}</div>
                                        <div>{startWeek.plus({day: weekDay.value}).toFormat('LLL dd')}</div>
                                    </div>
                                </MenuItem>))}
                            </Select>

                            <div className={'separator'}/>

                            <Button onClick={() => handleDayChange(-1)} className={'day-selection-button'}>
                                <KeyboardArrowLeftIcon className={'icon'}/>
                            </Button>
                            <Button onClick={() => handleDayChange(1)} className={'day-selection-button'}>
                                <KeyboardArrowRightIcon className={'icon'}/>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={'graph-pane'}>
                    {(!reportSwimmerHistoryData) && <LoadingSpinner top={140} left={90}/>}
                    <ResponsiveContainer width={'107.8%'} height={'80%'}>
                        <LineChart className={'line-chart'}
                                   data={reportSwimmerHistoryData || []}
                                   margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                            <Legend iconType={'circle'}
                                    iconSize={8} align={'left'}
                                    verticalAlign={"top"}
                                    fontSize={6}
                                    formatter={legendFormatter}/>
                            <CartesianGrid
                                stroke="#9DADBC"
                                strokeDasharray="10 10"
                                strokeWidth={0.4}
                                vertical={false}/>
                            <XAxis stroke={'#9DADBC'}
                                   tickLine={false}
                                   interval={11}
                                   strokeWidth={0}
                                   dataKey={'time'}
                                   fontSize={11}/>
                            <YAxis stroke={'#9DADBC'}
                                   strokeWidth={0}
                                   fontSize={11}/>
                            <Line isAnimationActive
                                  animationDuration={1000}
                                  dot={false} type="monotone"
                                  dataKey="swimmerCount" stroke="#57A6DB"/>
                            <Line isAnimationActive
                                  animationDuration={1000}
                                  dot={false} type="monotone"
                                  dataKey="swimmerCountAvg"
                                  stroke="#6A6EAD"/>
                            <Line isAnimationActive={false}
                                  dot={<CustomDot/>}
                                  dataKey="alerts.value" stroke="none"/>
                            <Tooltip
                                cursor={{stroke: '#57A6DB', strokeWidth: 0.5, r: 11, fill: 'red'}}
                                content={<CustomTooltip/>}/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
        <div className={'right-floating-pane'}>
            <ReportsDiagramPane key={Math.random() + 'key'} poolId={pools[selectedPoolIndex]?.id}
                                alerts={hoveredAlert ? [hoveredAlert] : alerts}
                                data={heatMapData ? heatMapData[weekDay] : {max: -1, data: []}}
                                transformation={pools[selectedPoolIndex]?.transformation}
                                poolArea={pools[selectedPoolIndex]?.area}/>
        </div>
        <div className={'bottom-pane'}>
            <ImageCarousel selectEvent={processSelectedEventFromCarousel}
                           items={alertWithImagesArraySorted}
                           selectedIndex={selectedAlertIndex}
            />
        </div>
    </div>
}
ReportsPoolUtilizationPage.propTypres = {}
export default ReportsPoolUtilizationPage
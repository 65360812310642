import {DateTime} from "luxon";
import {authorizeToken, fetchRefreshToken, logout} from "../api/api";
import mixpanel from "mixpanel-browser";
import axios from "axios";
import {defaultCountries} from "react-international-phone";

let shouldRefreshToken = true
export const dateEuFormat = 'DD/MM/YYYY'
export const hourFormat = 'HH:mm'
export const shortString = (str, maxLength) => {
    if (!str) return ""
    if (str.length > maxLength) {
        return str.substring(0, maxLength) + '..'
    } else {
        return str
    }
}
export const defaultOpeningHours = [
    {day:'sunday',open:'08:00',close:"20:00"},
    {day:'monday',open:'08:00',close:"20:00"},
    {day:'tuesday',open:'08:00',close:"20:00"},
    {day:'wednesday',open:'08:00',close:"20:00"},
    {day:'thursday',open:'08:00',close:"20:00"},
    {day:'friday',open:'08:00',close:"20:00"},
    {day:'saturday',open:'08:00',close:"20:00"}
    ]
export const time = [
    {value: 0 , label: '00:00'},
    {value: 15 , label: '00:15'},
    {value: 30 , label: '00:30'},
    {value: 45 , label: '00:45'},
    {value: 60 , label: '01:00'},
    {value: 75 , label: '01:15'},
    {value: 90 , label: '01:30'},
    {value: 105 , label: '01:45'},
    {value: 120 , label: '02:00'},
    {value: 135 , label: '02:15'},
    {value: 150 , label: '02:30'},
    {value: 165 , label: '02:45'},
    {value: 180 , label: '03:00'},
    {value: 195 , label: '03:15'},
    {value: 210 , label: '03:30'},
    {value: 225 , label: '03:45'},
    {value: 240 , label: '04:00'},
    {value: 255 , label: '04:15'},
    {value: 270 , label: '04:30'},
    {value: 285 , label: '04:45'},
    {value: 300 , label: '05:00'},
    {value: 315 , label: '05:15'},
    {value: 330 , label: '05:30'},
    {value: 345 , label: '05:45'},
    {value: 360 , label: '06:00'},
    {value: 375 , label: '06:15'},
    {value: 390 , label: '06:30'},
    {value: 405 , label: '06:45'},
    {value: 420 , label: '07:00'},
    {value: 435 , label: '07:15'},
    {value: 450 , label: '07:30'},
    {value: 465 , label: '07:45'},
    {value: 480 , label: '08:00'},
    {value: 495 , label: '08:15'},
    {value: 510 , label: '08:30'},
    {value: 525 , label: '08:45'},
    {value: 540 , label: '09:00'},
    {value: 555 , label: '09:15'},
    {value: 570 , label: '09:30'},
    {value: 585 , label: '09:45'},
    {value: 600 , label: '10:00'},
    {value: 615 , label: '10:15'},
    {value: 630 , label: '10:30'},
    {value: 645 , label: '10:45'},
    {value: 660 , label: '11:00'},
    {value: 675 , label: '11:15'},
    {value: 690 , label: '11:30'},
    {value: 705 , label: '11:45'},
    {value: 720 , label: '12:00'},
    {value: 735 , label: '12:15'},
    {value: 750 , label: '12:30'},
    {value: 765 , label: '12:45'},
    {value: 780 , label: '13:00'},
    {value: 795 , label: '13:15'},
    {value: 810 , label: '13:30'},
    {value: 825 , label: '13:45'},
    {value: 840 , label: '14:00'},
    {value: 855 , label: '14:15'},
    {value: 870 , label: '14:30'},
    {value: 885 , label: '14:45'},
    {value: 900 , label: '15:00'},
    {value: 915 , label: '15:15'},
    {value: 930 , label: '15:30'},
    {value: 945 , label: '15:45'},
    {value: 960 , label: '16:00'},
    {value: 975 , label: '16:15'},
    {value: 990 , label: '16:30'},
    {value: 1005 , label: '16:45'},
    {value: 1020 , label: '17:00'},
    {value: 1035 , label: '17:15'},
    {value: 1050 , label: '17:30'},
    {value: 1065 , label: '17:45'},
    {value: 1080 , label: '18:00'},
    {value: 1095 , label: '18:15'},
    {value: 1110 , label: '18:30'},
    {value: 1125 , label: '18:45'},
    {value: 1140 , label: '19:00'},
    {value: 1155 , label: '19:15'},
    {value: 1170 , label: '19:30'},
    {value: 1185 , label: '19:45'},
    {value: 1200 , label: '20:00'},
    {value: 1215 , label: '20:15'},
    {value: 1230 , label: '20:30'},
    {value: 1245 , label: '20:45'},
    {value: 1260 , label: '21:00'},
    {value: 1275 , label: '21:15'},
    {value: 1290 , label: '21:30'},
    {value: 1305 , label: '21:45'},
    {value: 1320 , label: '22:00'},
    {value: 1335 , label: '22:15'},
    {value: 1350 , label: '22:30'},
    {value: 1365 , label: '22:45'},
    {value: 1380 , label: '23:00'},
    {value: 1395 , label: '23:15'},
    {value: 1410 , label: '23:30'},
    {value: 1425 , label: '23:45'},
]
export const timeFrames15minutes = [
    {value: 15, label: '15 minutes'},
    {value: 30, label: '30 minutes'},
    {value: 45, label: '45 minutes'},
    {value: 60, label: '1:00 hour'},
    {value: 75, label: '1:15 hours'},
    {value: 90, label: '1:30 hours'},
    {value: 105, label: '1:45 hours'},
    {value: 120, label: '2:00 hours'},
]
export const mockDataSwimmersHistory= [
    {
        "time": "2024-03-14T03:30:00.000+02:00",
        "swimmer_count": 0
    },
    {
        "time": "2024-03-14T03:40:00.000+02:00",
        "swimmer_count": 0
    },
    {
        "time": "2024-03-14T03:50:00.000+02:00",
        "swimmer_count": 0
    },
    {
        "time": "2024-03-14T04:00:00.000+02:00",
        "swimmer_count": 0
    },
    {
        "time": "2024-03-14T04:10:00.000+02:00",
        "swimmer_count": 0
    },
    {
        "time": "2024-03-14T04:20:00.000+02:00",
        "swimmer_count": 0
    },
    {
        "time": "2024-03-14T04:30:00.000+02:00",
        "swimmer_count": 0
    },
    {
        "time": "2024-03-14T04:40:00.000+02:00",
        "swimmer_count": 0
    },
    {
        "time": "2024-03-14T04:50:00.000+02:00",
        "swimmer_count": 0
    },
    {
        "time": "2024-03-14T05:00:00.000+02:00",
        "swimmer_count": 0
    },
    {
        "time": "2024-03-14T05:10:00.000+02:00",
        "swimmer_count": 0
    },
    {
        "time": "2024-03-14T05:20:00.000+02:00",
        "swimmer_count": 0
    },
    {
        "time": "2024-03-14T05:30:00.000+02:00",
        "swimmer_count": 7
    },
    {
        "time": "2024-03-14T05:40:00.000+02:00",
        "swimmer_count": 11
    },
    {
        "time": "2024-03-14T05:50:00.000+02:00",
        "swimmer_count": 10
    },
    {
        "time": "2024-03-14T06:00:00.000+02:00",
        "swimmer_count": 8
    },
    {
        "time": "2024-03-14T06:10:00.000+02:00",
        "swimmer_count": 6
    },
    {
        "time": "2024-03-14T06:20:00.000+02:00",
        "swimmer_count": 6
    },
    {
        "time": "2024-03-14T06:30:00.000+02:00",
        "swimmer_count": 6
    },
    {
        "time": "2024-03-14T06:40:00.000+02:00",
        "swimmer_count": 22
    },
    {
        "time": "2024-03-14T06:50:00.000+02:00",
        "swimmer_count": 30
    },
    {
        "time": "2024-03-14T07:00:00.000+02:00",
        "swimmer_count": 24
    },
    {
        "time": "2024-03-14T07:10:00.000+02:00",
        "swimmer_count": 24
    },
    {
        "time": "2024-03-14T07:20:00.000+02:00",
        "swimmer_count": 25
    },
    {
        "time": "2024-03-14T07:30:00.000+02:00",
        "swimmer_count": 24
    },
    {
        "time": "2024-03-14T07:40:00.000+02:00",
        "swimmer_count": 20
    },
    {
        "time": "2024-03-14T07:50:00.000+02:00",
        "swimmer_count": 18
    },
    {
        "time": "2024-03-14T08:00:00.000+02:00",
        "swimmer_count": 21
    },
    {
        "time": "2024-03-14T08:10:00.000+02:00",
        "swimmer_count": 24
    },
    {
        "time": "2024-03-14T08:20:00.000+02:00",
        "swimmer_count": 26
    },
    {
        "time": "2024-03-14T08:30:00.000+02:00",
        "swimmer_count": 27
    },
    {
        "time": "2024-03-14T08:40:00.000+02:00",
        "swimmer_count": 25
    },
    {
        "time": "2024-03-14T08:50:00.000+02:00",
        "swimmer_count": 10
    },
    {
        "time": "2024-03-14T09:00:00.000+02:00",
        "swimmer_count": 17
    },
    {
        "time": "2024-03-14T09:10:00.000+02:00",
        "swimmer_count": 20
    },
    {
        "time": "2024-03-14T09:20:00.000+02:00",
        "swimmer_count": 20
    },
    {
        "time": "2024-03-14T09:30:00.000+02:00",
        "swimmer_count": 22
    },
    {
        "time": "2024-03-14T09:40:00.000+02:00",
        "swimmer_count": 24
    },
    {
        "time": "2024-03-14T09:50:00.000+02:00",
        "swimmer_count": 24
    },
    {
        "time": "2024-03-14T10:00:00.000+02:00",
        "swimmer_count": 20
    },
    {
        "time": "2024-03-14T10:10:00.000+02:00",
        "swimmer_count": 7
    },
    {
        "time": "2024-03-14T10:20:00.000+02:00",
        "swimmer_count": 4
    },
    {
        "time": "2024-03-14T10:30:00.000+02:00",
        "swimmer_count": 6
    },
    {
        "time": "2024-03-14T10:40:00.000+02:00",
        "swimmer_count": 8
    },
    {
        "time": "2024-03-14T10:50:00.000+02:00",
        "swimmer_count": 9
    },
    {
        "time": "2024-03-14T11:00:00.000+02:00",
        "swimmer_count": 8
    },
    {
        "time": "2024-03-14T11:10:00.000+02:00",
        "swimmer_count": 9
    },
    {
        "time": "2024-03-14T11:20:00.000+02:00",
        "swimmer_count": 15
    },
    {
        "time": "2024-03-14T11:30:00.000+02:00",
        "swimmer_count": 13
    },
    {
        "time": "2024-03-14T11:40:00.000+02:00",
        "swimmer_count": 16
    },
    {
        "time": "2024-03-14T11:50:00.000+02:00",
        "swimmer_count": 14
    },
    {
        "time": "2024-03-14T12:00:00.000+02:00",
        "swimmer_count": 8
    },
    {
        "time": "2024-03-14T12:10:00.000+02:00",
        "swimmer_count": 8
    },
    {
        "time": "2024-03-14T12:20:00.000+02:00",
        "swimmer_count": 10
    },
    {
        "time": "2024-03-14T12:30:00.000+02:00",
        "swimmer_count": 11
    },
    {
        "time": "2024-03-14T12:40:00.000+02:00",
        "swimmer_count": 40
    },
    {
        "time": "2024-03-14T12:50:00.000+02:00",
        "swimmer_count": 9
    },
    {
        "time": "2024-03-14T13:00:00.000+02:00",
        "swimmer_count":48
    },
    {
        "time": "2024-03-14T13:10:00.000+02:00",
        "swimmer_count": 7
    },
    {
        "time": "2024-03-14T13:20:00.000+02:00",
        "swimmer_count": 45
    },
    {
        "time": "2024-03-14T13:30:00.000+02:00",
        "swimmer_count": 4
    },
    {
        "time": "2024-03-14T13:40:00.000+02:00",
        "swimmer_count": 58
    },
    {
        "time": "2024-03-14T13:50:00.000+02:00",
        "swimmer_count": 9
    },
    {
        "time": "2024-03-14T14:00:00.000+02:00",
        "swimmer_count": 49
    },
    {
        "time": "2024-03-14T14:10:00.000+02:00",
        "swimmer_count": 58
    },
    {
        "time": "2024-03-14T14:20:00.000+02:00",
        "swimmer_count": 66
    },
    {
        "time": "2024-03-14T14:30:00.000+02:00",
        "swimmer_count": 79
    },
    {
        "time": "2024-03-14T14:40:00.000+02:00",
        "swimmer_count": 71
    },
    {
        "time": "2024-03-14T14:50:00.000+02:00",
        "swimmer_count": 53
    },
    {
        "time": "2024-03-14T15:00:00.000+02:00",
        "swimmer_count": 42
    },
    {
        "time": "2024-03-14T15:10:00.000+02:00",
        "swimmer_count": 77
    }
]
export const mockedDataWatchesConnected = [
    {is_watch_on_body: true, lifeguard_name:"Natan",id:"0C:8D:CA:6C:90:C1"},
    {is_watch_on_body: true, lifeguard_name:"Nevuchadnatzer",id:"0C:8D:CA:6C:90:A1"},
    {is_watch_on_body: false, lifeguard_name:"John",id:"AA:AA:CA:6C:90:A1"},
    {is_watch_on_body: true, lifeguard_name:"Ramzi Abu Ramzi",id:"0C:8D:CA:6C:90:A2"},
    {is_watch_on_body: true, lifeguard_name:"Johann Sebastian Bach",id:"0C:8D:CA:6C:90:A3"}
]
export const mockDataLocations=[
    {
        "x": 0,
        "y": 0
    },
    {
        "x": 2,
        "y": 2
    },
    {
        "x": 4,
        "y": 4
    },
    {
        "x": 6,
        "y": 6
    },
    {
        "x": 0,
        "y": 12.5
    },
    {
        "x": 25,
        "y": 0
    },
    {
        "x": 25,
        "y": 12.5
    },
    {
        "x": 12.073,
        "y": 7.274
    },
    {
        "x": 20,
        "y": 6.9
    },
    {
        "x": 12.452,
        "y": 9.243
    },
    {
        "x": 19.482,
        "y": 5.005
    },
    {
        "x": 10.743,
        "y": 9.068
    }
]

export const handleLogout = () => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, { debug: true, track_pageview: true, persistence: 'localStorage' });
    mixpanel.track('Logout', {username:getNodeContext().username})
    mixpanel.reset()
    logout().then(()=>{
        window.location.reload();
    })
    sessionStorage.removeItem('node_context');
}

export const handleRefreshToken = async () => {
    if(shouldRefreshToken){
        shouldRefreshToken = false
        const currContext = getNodeContext()
        const data = await fetchRefreshToken(currContext.token,currContext.refreshToken)
        if(!data){
            handleLogout()
        }
        currContext.token = data.token
        currContext.refreshToken = data.refreshToken
        setNodeContext(currContext)
        window.location.reload();
        shouldRefreshToken = true
    }

}

export const envMap = {'development': 'prod', 'staging': 'stage', 'production': 'prod'}
export const languageToDateRangeFormat = {
    'en': 'en',
    'de': 'de',
    'nw': 'nb'
}
export const weekDays = [
    {titleIl: "sunday", title: "monday", value: 0},
    {titleIl: "monday", title: "tuesday", value: 1},
    {titleIl: "tuesday", title: "wednesday", value: 2},
    {titleIl: "wednesday", title: "thursday", value: 3},
    {titleIl: "thursday", title: "friday", value: 4},
    {titleIl: "friday", title: "saturday", value: 5},
    {titleIl: "saturday", title: "sunday", value: 6},
]
export const getNodeToken = () => {
    try {
        return JSON.parse(sessionStorage.getItem('node_context')).token
    } catch (ex) {
        console.log("Error parsing JSON: ", ex)
        return "No Token"
    }
}

export function setNodeContext(context) {
    sessionStorage.setItem('node_context', JSON.stringify(context))
}

export const getNodeContext = () => {
    try {
        let context = JSON.parse(sessionStorage.getItem('node_context'))
        let token
        if (!context) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            token = urlParams.get('token')
            if (token) {
                authorizeToken(token).then(data => {
                    context = data.data
                    setNodeContext(context)
                    window.location.href = '/';
                })
            }
        }

        return context
    } catch (ex) {
        console.log("Error parsing JSON: ", ex)
        return {}
    }
}
export const getCurrentTime = (timezone, locale, formatString) => DateTime.now().setZone(timezone).setLocale(locale).toFormat(formatString)
export const getStartEndDates = (value, timezone = '') => {
    try {
        const nodeContext = getNodeContext()
        timezone = nodeContext.timezone
        const start = DateTime.fromISO(value.start.format('YYYY-MM-DDTHH:mm:ss.sss'), {zone: timezone}).toUTC().toISO()
        const end = DateTime.fromISO(value.end.format('YYYY-MM-DDTHH:mm:ss.sss'), {zone: timezone}).toUTC().toISO()
        return {start, end}
    } catch (e) {
        console.log("Error in getStartEndDates", e)
    }
}

export const reportHeaders = {
    safetyEvents: ['Pool', 'Date', 'Time', 'Description'],
    lifeguardReports: ['Pool', 'Date', 'Time', 'Description'],
    swimmersHistory: ['Date', 'Time', 'Max Swimmer Count']
}

export const getOrgCountry = () => {
    const nodeContext = getNodeContext()
    return nodeContext.country
}
export const getCountryPrefix = (country) =>{
    let code = getCountryCodeByName(country).toLowerCase()
    const c = defaultCountries.find((item)=>item[1]=== code )
    return c?c[2]:""
}
export const getCountryCodeByName = (countryName) => {
    return countryNameToCode[countryName] || 'IL'
}
const countryNameToCode = {
    "Afghanistan": "AF",
    "Albania": "AL",
    "Algeria": "DZ",
    "American Samoa": "AS",
    "Andorra": "AD",
    "Angola": "AO",
    "Anguilla": "AI",
    "Antarctica": "AQ",
    "Antigua and Barbuda": "AG",
    "Argentina": "AR",
    "Armenia": "AM",
    "Aruba": "AW",
    "Australia": "AU",
    "Austria": "AT",
    "Azerbaijan": "AZ",
    "Bahrain": "BH",
    "Bangladesh": "BD",
    "Barbados": "BB",
    "Belarus": "BY",
    "Belgium": "BE",
    "Belize": "BZ",
    "Benin": "BJ",
    "Bermuda": "BM",
    "Bhutan": "BT",
    "Bosnia and Herzegovina": "BA",
    "Botswana": "BW",
    "Bouvet Island": "BV",
    "Brazil": "BR",
    "Brunei Darussalam": "BN",
    "Bulgaria": "BG",
    "Burkina Faso": "BF",
    "Burundi": "BI",
    "Cabo Verde": "CV",
    "Cambodia": "KH",
    "Cameroon": "CM",
    "Canada": "CA",
    "Chad": "TD",
    "Chile": "CL",
    "China": "CN",
    "Christmas Island": "CX",
    "Colombia": "CO",
    "Costa Rica": "CR",
    "Croatia": "HR",
    "Cuba": "CU",
    "Cyprus": "CY",
    "Czechia": "CZ",
    "Denmark": "DK",
    "Djibouti": "DJ",
    "Dominica": "DM",
    "Ecuador": "EC",
    "Egypt": "EG",
    "El Salvador": "SV",
    "Equatorial Guinea": "GQ",
    "Eritrea": "ER",
    "Estonia": "EE",
    "Eswatini": "SZ",
    "Ethiopia": "ET",
    "Fiji": "FJ",
    "Finland": "FI",
    "France": "FR",
    "French Guiana": "GF",
    "French Polynesia": "PF",
    "Gabon": "GA",
    "Georgia": "GE",
    "Germany": "DE",
    "Ghana": "GH",
    "Gibraltar": "GI",
    "Greece": "GR",
    "Greenland": "GL",
    "Grenada": "GD",
    "Guadeloupe": "GP",
    "Guam": "GU",
    "Guatemala": "GT",
    "Guernsey": "GG",
    "Guinea": "GN",
    "Guinea-Bissau": "GW",
    "Guyana": "GY",
    "Haiti": "HT",
    "Heard Island and McDonald Islands": "HM",
    "Holy See": "VA",
    "Honduras": "HN",
    "Hong Kong": "HK",
    "Hungary": "HU",
    "Iceland": "IS",
    "India": "IN",
    "Indonesia": "ID",
    "Iran": "IR",
    "Iraq": "IQ",
    "Ireland": "IE",
    "Isle of Man": "IM",
    "Israel": "IL",
    "Italy": "IT",
    "Jamaica": "JM",
    "Japan": "JP",
    "Jersey": "JE",
    "Jordan": "JO",
    "Kazakhstan": "KZ",
    "Kenya": "KE",
    "Kiribati": "KI",
    "Korea": "KR",
    "Kuwait": "KW",
    "Kyrgyzstan": "KG",
    "Lao People's Democratic Republic": "LA",
    "Latvia": "LV",
    "Lebanon": "LB",
    "Lesotho": "LS",
    "Liberia": "LR",
    "Libya": "LY",
    "Liechtenstein": "LI",
    "Lithuania": "LT",
    "Luxembourg": "LU",
    "Macao": "MO",
    "Madagascar": "MG",
    "Malawi": "MW",
    "Malaysia": "MY",
    "Maldives": "MV",
    "Mali": "ML",
    "Malta": "MT",
    "Martinique": "MQ",
    "Mauritania": "MR",
    "Mauritius": "MU",
    "Mayotte": "YT",
    "Mexico": "MX",
    "Monaco": "MC",
    "Mongolia": "MN",
    "Montenegro": "ME",
    "Montserrat": "MS",
    "Morocco": "MA",
    "Mozambique": "MZ",
    "Myanmar": "MM",
    "Namibia": "NA",
    "Nauru": "NR",
    "Nepal": "NP",
    "Netherlands": "NL",
    "New Caledonia": "NC",
    "New Zealand": "NZ",
    "Nicaragua": "NI",
    "Niger": "NE",
    "Nigeria": "NG",
    "Niue": "NU",
    "Norfolk Island": "NF",
    "Northern Mariana Islands": "MP",
    "Norway": "NO",
    "Oman": "OM",
    "Pakistan": "PK",
    "Palau": "PW",
    "Palestine, State of": "PS",
    "Panama": "PA",
    "Papua New Guinea": "PG",
    "Paraguay": "PY",
    "Peru": "PE",
    "Philippines": "PH",
    "Pitcairn": "PN",
    "Poland": "PL",
    "Portugal": "PT",
    "Puerto Rico": "PR",
    "Qatar": "QA",
    "Republic of North Macedonia": "MK",
    "Romania": "RO",
    "Russian Federation": "RU",
    "Rwanda": "RW",
    "Réunion": "RE",
    "Saint Barthélemy": "BL",
    "Saint Helena, Ascension and Tristan da Cunha": "SH",
    "Saint Kitts and Nevis": "KN",
    "Saint Lucia": "LC",
    "Saint Martin": "MF",
    "Saint Pierre and Miquelon": "PM",
    "Saint Vincent and the Grenadines": "VC",
    "Samoa": "WS",
    "San Marino": "SM",
    "Sao Tome and Principe": "ST",
    "Saudi Arabia": "SA",
    "Senegal": "SN",
    "Serbia": "RS",
    "Seychelles": "SC",
    "Sierra Leone": "SL",
    "Singapore": "SG",
    "Sint Maarten": "SX",
    "Slovakia": "SK",
    "Slovenia": "SI",
    "Solomon Islands": "SB",
    "Somalia": "SO",
    "South Africa": "ZA",
    "South Georgia and the South Sandwich Islands": "GS",
    "South Sudan": "SS",
    "Spain": "ES",
    "Sri Lanka": "LK",
    "Sudan": "SD",
    "Suriname": "SR",
    "Svalbard and Jan Mayen": "SJ",
    "Sweden": "SE",
    "Switzerland": "CH",
    "Syrian Arab Republic": "SY",
    "Taiwan": "TW",
    "Tajikistan": "TJ",
    "Tanzania, United Republic of": "TZ",
    "Thailand": "TH",
    "Timor-Leste": "TL",
    "Togo": "TG",
    "Tokelau": "TK",
    "Tonga": "TO",
    "Trinidad and Tobago": "TT",
    "Tunisia": "TN",
    "Turkey": "TR",
    "Turkmenistan": "TM",
    "Turks and Caicos Islands": "TC",
    "Tuvalu": "TV",
    "Uganda": "UG",
    "Ukraine": "UA",
    "United Arab Emirates ": "AE",
    "United Kingdom of Great Britain and Northern Ireland": "GB",
    "UK": "GB",
    "United States Minor Outlying Islands": "UM",
    "United States of America": "US",
    "Uruguay": "UY",
    "Uzbekistan": "UZ",
    "Vanuatu": "VU",
    "Venezuela": "VE",
    "Viet Nam": "VN",
    "Virgin Islands": "VG",
    "Wallis and Futuna": "WF",
    "Western Sahara": "EH",
    "Yemen": "YE",
    "Zambia": "ZM",
    "Zimbabwe": "ZW",
    "Åland Islands": "AX",
}




export const clearSpaces = (str) =>{
    return (str||"").replaceAll(' ','')
}

export const removeUnchanged = (original,obj) =>{
    let keys = Object.keys(obj)
    let res = {}
    keys.forEach((key)=>{
        if(!obj[key] && !original[key]){
            return
        }
        if(obj[key] !== original[key]){
            res[key] = obj[key]
        }

    })
    return res
}

export const clearEmptyFields = (obj) =>{
    let keys = Object.keys(obj)
    keys.forEach((key)=>{
        if(!obj[key]){
            delete obj[key]
        }
    })
    return obj
}

export const handleSuccessfulLogin = (res) => {
    const _context = {
        poolIds: res.data.data.pools,
        ...res.data.data
    }
    delete _context.pools
    setNodeContext(_context);
    return _context
}

export class MFAException extends Error {
    constructor(token, message,method) {
        super(message);
        this.token = token
        this.method = method
    }

}

export const authenticateNode = async (username, password) => {
    const url = `${process.env.REACT_APP_NODE_BE_PATH}/login`
    const body = {username, password}
    const res = await axios.post(url, body)
    if(res.status === 202){
        throw new MFAException(res.data.data.token,"MFA required",res.data.data.method)
    }
    return handleSuccessfulLogin(res)
}
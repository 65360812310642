import {DialogContent, Menu, MenuItem} from "@mui/material";
import {useState} from 'react';
import ConfirmLogout from "./ConfirmLogout";
import {useTranslation} from "react-i18next";
import {getNodeContext, setNodeContext} from "../../utils/utils";
import Snackbar from "@mui/material/Snackbar";
import SiteSettingsDrawer from "./SiteSettingsDrawer/SiteSettingsDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {renewContext} from "../../api/api";

const DropdownMenu = props => {

    const [t] = useTranslation()
    const [confirmWindow, setConfirmWindow] = useState(false)
    const [settingsWindow, setSettingsWindow] = useState(false)
    const [errorState, setErrorState] = useState(false)
    const [snackMessage, setSnackMessage] = useState('')
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null)
    const [subSubMenuAnchorEl, setSubSubMenuAnchorEl] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [popupSites, setPopupSites] = useState([])
    let isStaff = false
    const path = window.location.pathname
    const context = getNodeContext()
    isStaff = context.isStaff

    const handleOpenConfirmWindow = () => {
        setConfirmWindow(true)
    }
    const handleOpenSettingsWindow = () => {
        props.handleMenuClose()
        setSettingsWindow(true)
    }

    const handleReportsSelection = () => {
        window.location.href = '/reports';
    }
    const handleDashboardSelection = () => {
        window.location.href = '/dashboard';
    }

    const handleCloseConfirmWindow = () => {
        setConfirmWindow(false)
    }

    const handleCloseSettingsWindow = () => {
        setSettingsWindow(false)
    }
    const handleAdminModeSwitch = () => {
        props.setAdminMode()
        props.handleMenuClose()

    }

    function activateSnackbar(_message, _errorState) {
        setSnackMessage(_message)
        setErrorState(_errorState)
    }

    function handleSnackClose() {
        setSnackMessage('')
    }

    function switchSite(siteId) {
        renewContext(siteId).then((context) => {
            const oldContext = getNodeContext()
            const newContext = Object.assign(oldContext, context.data)
            setNodeContext(newContext)
            window.location.href = '/dashboard'
        })
    }

    function sortLexico(a,b,ascendingOrder=true) {
        if (a.orgName < b.orgName) {
            return ascendingOrder ? -1 : 1;
        }
        if (a.orgName > b.orgName) {
            return ascendingOrder ? 1 : -1;
        }
    }

    function renderMenuItems() {
        return context.accessPermissions.organizations.sort(sortLexico).map((org, index) => {
            return <MenuItem id={`org-${index}`}
                             style={{position: "relative"}}
                             onClick={() => setPopupSites(org.sites)}
            >{org.orgName}
            </MenuItem>

        })
    }

    function handleSubMenuClose() {
        setSubMenuAnchorEl(null)
    }

    const toggleMenuClick = (event) => {
        anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSubMenuAnchorEl(null);
        setSubSubMenuAnchorEl(null);
    };

    return (<div>
        <Dialog open={!!popupSites?.length} onClose={handleSubMenuClose}>
            <DialogTitle>
                {t('selectSite')}
            </DialogTitle>
            <DialogContent>
                <div style={{padding:'10px',dispaly: 'flex' , width: 'fit-content',minWidth:'300px',maxWidth:'850px', minHeight: '180px'}}>
                    {popupSites?.map((site, index) => {
                        return <MenuItem style={{width:'100%'}} key={index} onClick={() => switchSite(site.siteId)}>{site.siteTitle}</MenuItem>

                    })}
                </div>
            </DialogContent>
            <DialogActions>
                <Button id={'close-button'} onClick={() => setPopupSites(null)}>{t('cancel')}</Button>
            </DialogActions>
        </Dialog>


        <Snackbar
            open={!!snackMessage}
            autoHideDuration={5000}
            onClose={handleSnackClose}
            message={snackMessage}
            ContentProps={{sx: {backgroundColor: errorState ? '#ed7847' : '#33bb2d'}}}
            style={{borderRadius: 4}}
            anchorOrigin={{horizontal: 'right', vertical: "top"}}
        />
        <Menu
            id="dropdown-menu"
            anchorEl={props.menuPos}
            open={props.open}
            onClose={props.handleMenuClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {'/reports' !== path && <MenuItem onClick={handleReportsSelection}>{t('reports')}</MenuItem>}
            {'/dashboard' !== path && <MenuItem onClick={handleDashboardSelection}>{t('dashboard')}</MenuItem>}
            {isStaff && <MenuItem style={{color: props.adminMode ? 'red' : 'green'}}
                                  onClick={handleAdminModeSwitch}>{props.adminMode ? "Admin mode" : "User mode"}</MenuItem>}
            {!context.isAnonymous && <MenuItem onClick={handleOpenSettingsWindow}>{t('settings')}</MenuItem>}
            {context.level==='organization' && context.accessPermissions?.organizations?.length ===1 && context.accessPermissions?.organizations[0].sites.length >=1? <MenuItem onClick={toggleMenuClick}>
                {t('changeSite')}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {context.accessPermissions.organizations[0].sites.sort(sortLexico).map((site, index) => {
                        return <MenuItem key={index} onClick={() => switchSite(site.siteId)}>{site.siteTitle}</MenuItem>

                    })}
                </Menu>
            </MenuItem> : null

            }
            {context.level==='region' && context.accessPermissions?.organizations?.length? <MenuItem onClick={toggleMenuClick}>
                {t('changeSite')}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}


                >
                    {renderMenuItems()}
                </Menu>
            </MenuItem> : null}
            <MenuItem onClick={handleOpenConfirmWindow}>{t('logout')}</MenuItem>
        </Menu>
        <ConfirmLogout openState={confirmWindow} handleConfirmClose={handleCloseConfirmWindow}/>
        <SiteSettingsDrawer
            openState={settingsWindow}
            handleSettingsClose={handleCloseSettingsWindow}
            activateSnackbar={activateSnackbar}/>
    </div>)
}


export default DropdownMenu;
import axios from "axios";
import {getNodeToken, handleRefreshToken} from "../utils/utils";

function processError(error) {
    if (error && error.request && error.request.status === 401) {
        console.warn("Token expired\n ================================\n need to login again",)
        handleRefreshToken()
    }
}

export const saveNewAlertSilenceRegime = async (siteId, data) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_NODE_BE_PATH}/site/${siteId}/settings/alert-silence`, data, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.status
    } catch (e) {
        console.log('Error  in -> saveNewDisableAlertRegime', e)
        processError(e)
        throw e
    }
}
export const deleteAlertSilenceRegime = async (siteId, data) => {
    try {
        const res = await axios.delete(`${process.env.REACT_APP_NODE_BE_PATH}/site/${siteId}/settings/alert-silence`, {
            data, headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.status
    } catch (e) {
        console.log('Error  in -> saveNewDisableAlertRegime', e)
        processError(e)
        throw e
    }
}

export const fetchSiteLiveData = async (siteId) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/live-data/${siteId}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data.pools
    } catch (e) {
        console.log('Error  in -> fetchSiteLiveData', e)
        processError(e)
        return []
    }

}
export const senAuthenticationEmail = async (email) => {
    const res = await axios.post(`${process.env.REACT_APP_NODE_BE_PATH}/login/email`, {email},)
    return res.data.status
}
export const fetchSite = async (siteId) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/${siteId}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchSite', e)
        processError(e)
        return []
    }

}

export const fetchOrg = async (orgId) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/organization/${orgId}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchOrg', e)
        processError(e)
        return []
    }

}

export const fetchSiteSwimmerHistory = async (siteId, start, end) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/swimmer-history/${siteId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchSiteSwimmerHistory', e)
        processError(e)
        return []
    }

}

export const fetchSiteSwimmerHistoryFilterByPoolId = async (siteId, start, end, poolId = -1) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/swimmer-history/${siteId}?startTime=${start}&endTime=${end}&report=true&poolId=${poolId}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data.find(e => e.id === poolId) || {}
    } catch (e) {
        console.log('Error  in -> fetchSiteSwimmerHistoryFilterByPoolId', e)
        processError(e)
        return []
    }

}

export const fetchSiteSafetyEvents = async (siteId, start, end) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/safety-events/${siteId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchSiteSafetyEvents', e)
        processError(e)
        return []
    }

}

export const fetchSiteSafetyEventsFilterByPoolId = async (siteId, start, end, poolId = -1) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/safety-events/${siteId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data.find(e => e.id === poolId) || {}
    } catch (e) {
        console.log('Error  in -> fetchSiteSafetyEventsFilterByPoolId', e)
        processError(e)
        return []
    }

}

export const fetchSiteLifeguardReports = async (siteId, start, end) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/lifeguard-reports/${siteId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchSiteLifeguardReports', e)
        processError(e)
        return []
    }

}
export const logout = async () => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_NODE_BE_PATH}/login/exit`, {},
            {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return
    } catch (e) {
        console.log('Error  in -> logout', e)
        processError(e)
        return []
    }
}
export const fetchPoolHeatmapData = async (start, end, poolId) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/pool/heatmap/${poolId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchPoolHeatmapData', e)
        processError(e)
        return []
    }
}
export const fetchPoolAlertsAndImages = async (start, end, poolId) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/pool/alerts/${poolId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchPoolAlerts', e)
        processError(e)
        return {}
    }
}
export const fetchSiteSafetyEventsTableData = async (start, end, siteId, poolId = -1) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/safety-events-table/${siteId}/${poolId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchSiteSafetyEventsTableData', e)
        processError(e)
        return []
    }

}

export const fetchSiteSafetyEventsGraphData = async (start, end, siteId, poolId = -1) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/safety-events-graph/${siteId}/${poolId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchSiteSafetyEventsGraphData', e)
        processError(e)
        return []
    }

}

export const fetchSiteLifeguardReportsTableData = async (start, end, siteId, poolId = -1) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/lifeguard-reports-table/${siteId}/${poolId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchSiteLifeguardReportsTableData', e)
        processError(e)
        return []
    }
}

export const renewContext = async (siteId)=>{
    try {
    const result = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/login/switch/site/${siteId}`, {
        headers: {'Authorization': `token ${getNodeToken()}`}
    })
        return  result.data
    }catch (e){
        console.log("Error", e)
    }
}

export const fetchSiteLifeguardReportsGraphData = async (start, end, siteId, poolId = -1) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/lifeguard-reports-graph/${siteId}/${poolId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchSiteLifeguardReportsGraphData', e)
        processError(e)
        return []
    }
}

export const fetchPoolConnectedWatchesGraphData = async (start, end, poolId) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/pool/connected-watches-graph/${poolId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchPoolConnectedWatchesGraphData', e)
        processError(e)
        return []
    }
}
export const authorizeToken = async (token = -1) => {
    const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/login/authorize`, {
        headers: {'Authorization': `Bearer ${token}`}
    })
    return res.data
}

export const getPoolReport = async (poolId, startTime, endTime) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/pool/report/${poolId}?startTime=${startTime}&endTime=${endTime}`, {
            headers: {'Authorization': `Bearer ${getNodeToken()}`}
        })
        return res.data
    } catch (e) {
        console.log('Error  in -> getPoolReport', e)
        processError(e)
        return {}
    }

}
export const patchSite = async (siteId, payload) => {
    try {
        if (!payload.settings) {
            alert('No settings')
        }
        const res = await axios.patch(`${process.env.REACT_APP_NODE_BE_PATH}/site/${siteId}`, payload, {
            headers: {'Authorization': `Bearer ${getNodeToken()}`}
        })
        return res.status
    } catch (e) {
        console.log('Error  in -> patchSite', e)
        processError(e)
        return {}
    }
}
export const getLifeguardsBySiteId = async (siteId) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/lifeguards/site/${siteId}`, {
            headers: {'Authorization': `Bearer ${getNodeToken()}`}
        })
        return res.data
    } catch (e) {
        console.log('Error  in -> get lifeguards by site id', e)
        processError(e)
        return {}
    }
}

export const patchLifeguard = async (lifeguardId, siteId, data) => {
    try {
        const res = await axios.patch(`${process.env.REACT_APP_NODE_BE_PATH}/lifeguards/${siteId}/${lifeguardId}`, {...data}, {
            headers: {'Authorization': `Bearer ${getNodeToken()}`}, validateStatus: false
        })
        return res.status
    } catch (e) {
        console.log('Error', e)
        processError(e)
        return []
    }
}

export const createLifeguard = async (siteId, data) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_NODE_BE_PATH}/lifeguards/${siteId}/create`, {...data}, {
            headers: {'Authorization': `Bearer ${getNodeToken()}`}
        })
        return res.status
    } catch (e) {
        console.log('Error', e)
        processError(e)
        return []
    }
}
export const createBulkLifeguardViaExcel = async (siteId, data) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_NODE_BE_PATH}/lifeguards/${siteId}/bulk/create`, data, {
            headers: {
                'Authorization': `Bearer ${getNodeToken()}`,
            }
        })
        return res
    } catch (e) {
        console.log('Error', e)
        processError(e)
        return []
    }
}

export const deleteLifeguard = async (lifeguardId, siteId, pin) => {
    try {
        const res = await axios.delete(`${process.env.REACT_APP_NODE_BE_PATH}/lifeguards/${siteId}/${lifeguardId}`, {
            headers: {'Authorization': `Bearer ${getNodeToken()}`}, data: {pin}
        })
        return res.status
    } catch (e) {
        console.log('Error', e)
        processError(e)
        return []
    }
}

export const verifyMFACode = async (code, token) => {
    const res = await axios.post(`${process.env.REACT_APP_NODE_BE_PATH}/login/verify`, {code}, {
        headers: {'Authorization': `Bearer ${token}`}
    })
    return res

}

export const fetchRefreshToken = async (accessToken, refreshToken) => {
    const res = await axios.post(`${process.env.REACT_APP_NODE_BE_PATH}/login/refresh`, {refreshToken}, {
        headers: {'Authorization': `Bearer ${accessToken}`}, validateStatus: false
    })
    return res?.data?.data
}